import { graphql, Link } from "gatsby"
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import SEO from '../components/seo';
import Layout from '../components/Layout';


// const renderLoader = () => (
//   <div className="container h-100 d-flex justify-content-center">
//     <div className="jumbotron my-auto">
//       <Spinner animation="border" role="status">
//         <span className="sr-only">Loading...</span>
//       </Spinner>
//     </div>
//   </div>
// )

class BlogIndex extends React.Component {

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description
    const domainName = data.site.siteMetadata.domainName
    const posts = data.privateGraphql.news
    const { currentPage, numPages } = this.props.pageContext
    let curr = 1;
    if (currentPage === undefined) { curr = 1 }
    else { curr = currentPage }
    const isFirst = curr === 1
    const isLast = curr === numPages
    const prevPage = curr - 1 === 1 ? '/' : (curr - 1).toString()
    const nextPage = (curr + 1).toString()
    return (
        <Layout location={this.props.location} title={siteTitle}>
          <SEO
            title={`${siteTitle} ${curr === 1 ? '' : ` - Trang ${curr}`}`}
            description={`${siteDescription} ${curr === 1 ? '' : ` - Trang ${curr}`}`}
            canonical={`https://${domainName}`}
          />

          <div className="col-lg-8 col-md-12">
            <div className="row">
              {posts.map(node => {
                const title = node.title || node.slugtitle
                return (
                  <div className="col-md-6" key={node.slugtitle}>
                    <article className="archive-post mts-post">
                      <a href={`https://${domainName}/` + node.slugtitle} title={title} className="post-image post-image-full">
                        <div className="featured-thumbnail">
                        <img className="wp-post-image" 
                        alt={title}
                        title={title}
                        data-src={node.image}
                        src={node.image}
                        loading="lazy" />
                          {/* <LazyLoadImage
                            className="wp-post-image"
                            alt={title}
                            title={title}
                            data-src={node.image}
                            effect="blur"
                            loading="lazy"
                          /> */}
                        </div>
                      </a>
                      <div className="post-content">
                        <div className="post-meta">
                          <span className="theauthor">
                            <Link to={`/authors/${node.profileSlugName}`}>
                              {/* <LazyLoadImage
                                className="author-avatar"
                                title={node.profileName}
                                src={node.profileImage}
                                effect="blur"
                              /> */}
                              {node.profileName}
                            </Link>
                          </span>
                          <span className="thecategory">{node.categoryName !== null ? <Link to={`/${node.slugCategoryName}`}>{node.categoryName}</Link> : "Trading Tutorial"}</span>

                        </div>
                        <header>
                          <h2 className="post-title">
                            <a href={`https://${domainName}/` + node.slugtitle} title={title}>{title}</a></h2>
                        </header>
                        <div className="post-excerpt">
                          <p className="tb-post-card__subhead" dangerouslySetInnerHTML={{ __html: node.short }} />
                          <a href={`https://${domainName}/` + node.slugtitle} title={title} className="read-more-link">Leer más</a>
                        </div>
                      </div>
                    </article>
                  </div>
                )
              })}
            </div>
            <div className="row pagination">
              <ul className="col d-flex justify-content-center">
                {!isFirst && (
                  <li className="tb-pagination__link">
                    <Link to={curr <= 2 ? `/` : `/page/${prevPage}`} rel="prev">
                      ←
                </Link>
                  </li>
                )}
                {Array.from({ length: numPages }, (_, i) => {
                  if (i < curr + 3 && i > curr - 3) {
                    return (
                      <li
                        key={`pagination-number${i + 1}`}
                        className="tb-pagination__link"
                      >
                        <Link
                          to={`/${i === 0 ? '' : 'page/' + (i + 1)}`}
                          className={i + 1 === curr ? "current" : "page-s"}
                        >
                          {i + 1}
                        </Link>
                      </li>
                    )
                  }
                })}
                {!isLast && (
                  <li className="tb-pagination__link">
                    <Link to={`/page/${nextPage}`} rel="next">
                      →
                </Link>
                  </li>
                )}
              </ul>
            </div>

          </div>
        </Layout>
    )
  }
}
export default BlogIndex
export const query = graphql`
query ($limit: Int = 22, $skip: Int = 0, $siteId: Int = 1078) {
  site {
    siteMetadata {
      title
      domainName
      description
    }
  }
  privateGraphql {
    news(
      limit: $limit 
      skip: $skip,
      siteId: $siteId
      ) {
        id
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
        profileImage
    }
  }
}
`
